<template>
  <div class="signup-legal">
    <SignupOverlay>
      <Heading level="4" class="">
        {{ $t('signup.legal.title') }}
      </Heading>
      <Stepper :steps="steps" />
      <keep-alive>
        <router-view />
      </keep-alive>
    </SignupOverlay>
  </div>
</template>

<script>
import SignupOverlay from '@/components/Signup/SignupOverlay.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Stepper from '@/elements/Stepper/Stepper.vue';

export default {
  components: {
    SignupOverlay,
    Heading,
    Stepper,
  },
  data() {
    return {
      steps: [
        {
          title: 'Юридична особа',
          name: 'SignupLegalPerson',
        },
        {
          title: 'Контактна особа',
          name: 'SignupLegalContactPerson',
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.signup-legal .signup-overlay:before
  @media screen and (max-width: 600px)
    height: calc(100% - 212px)

.signup-legal .steps
  padding-top: 0

.signup-legal .steps__item
  flex: 0 0 35%
</style>
