<template>
  <div class="steps">
    <div class="steps__list" :style="cssTransformStyle">
      <router-link
        v-for="(item, idx) in steps"
        :key="item.title"
        :to="{ name: item.name }"
        :disabled="idx >= activeStep"
        class="steps__item"
        :class="{
          active: idx === activeStep,
          done: idx < activeStep,
          disabled: idx > activeStep,
        }"
      >
        <div class="steps__item-box">
          <div v-if="idx < activeStep" class="steps__item--check">
            <svg-icon name="step-check"></svg-icon>
          </div>
          <div class="steps__item-text">
            <span class="steps__item-number">
              <template v-if="isMobile">
                {{ idx + 1 }}
                <span class="steps__item-number-total">{{
                  `/${steps.length}`
                }}</span>
              </template>
              <template v-else>
                {{ idx + 1 }}
              </template>
            </span>
            <span class="steps__item-title">
              {{ item.title }}
            </span>
          </div>
        </div>
        <div class="steps__item-arrow"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeStep: 0,
      isMobile: false,
    };
  },
  computed: {
    cssTransformStyle() {
      if (this.isMobile) {
        let stepWidthByPercent = 70;
        let gapWidthByPercent = 16;
        let coefficient = this.activeStep;

        let isLastStep = this.activeStep === this.steps.length - 1;

        let totalStepsOffset = !isLastStep
          ? stepWidthByPercent * coefficient
          : stepWidthByPercent * coefficient - (100 - stepWidthByPercent);
        let totalGapsOffset = gapWidthByPercent * coefficient;

        return `transform: translateX(calc(-${totalStepsOffset}% - ${totalGapsOffset}px))`;
      }

      return '';
    },
  },
  watch: {
    $route(to, from) {
      const activeStepIndex = this.steps.findIndex(
        (item) => item.name === to.name
      );
      this.activeStep = activeStepIndex;
    },
  },
  created() {
    const currentStepIndex = this.steps.findIndex(
      (item) => item.name === this.$route.name
    );

    this.activeStep = currentStepIndex;
  },
  mounted() {
    if (document.documentElement.clientWidth <= 750) {
      this.setMobileMode();
    }

    window.addEventListener('resize', this.toggleMobileMode);
  },
  destroyed() {
    window.removeEventListener('resize', this.toggleMobileMode);
  },
  methods: {
    setMobileMode() {
      this.isMobile = true;
    },
    resetMobileMode() {
      this.isMobile = false;
    },
    toggleMobileMode() {
      if (document.documentElement.clientWidth <= 750) {
        this.setMobileMode();
      } else {
        this.resetMobileMode();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.steps
  border-bottom: 1px solid var(--primary-color-light-grey-2)
  padding-top: 40px
  padding-bottom: 48px

  @media screen and (max-width: 1199px)
    padding-bottom: 40px

  @media screen and (max-width: 767px)
    padding-top: 28px
    padding-bottom: 28px

.steps__list
  display: flex
  align-items: center
  justify-content: center
  gap: 16px

  @media screen and (max-width: 767px)
    justify-content: flex-start

  @media screen and (min-width: 768px) and (max-width: 1199px)
    gap: 12px

.steps__item
  display: flex
  align-items: center
  flex: 0 0 calc(25% - 12px)

  @media screen and (max-width: 1199px)
    flex: 0 0 calc(25% - 9px)

  @media screen and (max-width: 767px)
    flex: 0 0 70%!important

.steps__item-box,
.steps__item-arrow
  position: relative

.steps__item-box
  display: flex
  align-items: center
  padding: 0 5px 0 20px
  width: calc(100% - 24px)
  height: 80px
  border-radius: var(--primary-border-radius)
  border-width: 1px 0 1px 1px
  border-style: solid

  @media screen and (max-width: 1199px)
    width: calc(100% - 14px)
    height: 74px

  @media screen and (max-width: 767px)
    height: 48px
    padding: 0 5px 0 14px

.steps__item-arrow
  position: relative
  left: -1px
  border-top: 39px solid transparent
  border-bottom: 39px solid transparent
  border-left-width: 24px
  border-left-style: solid

  @media screen and (max-width: 1199px)
    border-top: 36px solid transparent
    border-bottom: 36px solid transparent
    border-left-width: 14px

  @media screen and (max-width: 767px)
    border-top: 23px solid transparent
    border-bottom: 23px solid transparent

.steps__item-arrow:after
  position: absolute
  content: ''
  top: -38px
  right: 1px
  width: 0
  height: 0
  border-top: 38px solid transparent
  border-bottom: 38px solid transparent
  border-left-width: 23px
  border-left-style: solid
  z-index: 3

  @media screen and (max-width: 1199px)
    top: -35px
    border-top: 35px solid transparent
    border-bottom: 35px solid transparent
    border-left-width: 13px

  @media screen and (max-width: 767px)
    top: -22px
    border-top: 22px solid transparent
    border-bottom: 22px solid transparent
    border-left-width: 14px

.steps__item--check
  position: absolute
  top: -10px
  left: -10px
  font-size: 0
  z-index: 10

  @media screen and (max-width: 767px)
    top: -8px
    left: -8px

.steps__item-text
  display: flex
  align-items: center
  gap: 12px
  z-index: 9

.steps__item-number
  font-weight: 600
  font-size: 32px
  line-height: 32px

  @media screen and (max-width: 1315px)
    font-size: 30px

  @media screen and (max-width: 1199px)
    font-size: 28px

  @media screen and (max-width: 767px)
    font-size: 20px
    line-height: 24px

.steps__item-number-total
  font-size: 15px

.steps__item-title
  font-weight: 600
  font-size: 24px
  line-height: 29px

  @media screen and (max-width: 1315px)
    font-size: 20px
    line-height: 24px

  @media screen and (max-width: 1199px)
    font-size: 16px
    line-height: 19px

  @media screen and (max-width: 767px)
    font-size: 14px
    line-height: 17px

.steps__item.done
  .steps__item-box
    border-color: var(--primary-color-dark-grey-1)

  .steps__item-arrow
    border-left-color: var(--primary-color-dark-grey-1)

    &:after
      border-left-color: var(--primary-color-white)

  .steps__item-number,
  .steps__item-text
    color: var(--primary-color-dark-blue)

.steps__item.active
  .steps__item-box
    border-color: var(--btn-color-blue)
    background-color: rgba(48, 83, 240, 0.04)

  .steps__item-arrow
    border-left-color: var(--btn-color-blue)

    &:after
      border-left-color: #f7f8fe

  .steps__item-number
    color: var(--btn-color-blue)

  .steps__item-text
    color: var(--primary-color-dark-blue)

.steps__item.disabled
  .steps__item-box
    border-color: rgba(40, 59, 89, 0.5)

  .steps__item-arrow
    border-left-color: rgba(40, 59, 89, 0.5)

    &:after
      border-left-color: var(--primary-color-white)

  .steps__item-number,
  .steps__item-text
    color: var(--primary-color-dark-grey-1)

.steps__item[disabled]
  pointer-events: none
  cursor: default

.icon-step-check
  @media screen and (max-width: 1199px)
    width: 20px
    height: 20px

  @media screen and (max-width: 767px)
    width: 16px
    height: 16px
</style>
